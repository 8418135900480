<template>
    <transition>
        <LoadingComponent v-if="v_loading_login.status" :message="v_loading_login.message"></LoadingComponent>
    </transition>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h1>
                    Clientes
                </h1>
                
                
                 <div class="input-group mb-3">
                    <input  v-model="query" type="text" class="form-control" placeholder="Buscar">
                    <span class="input-group-text" id="basic-addon1"><ClientCreateComponent :user="user"/></span>
                </div>

                
            </div>
            <div class="card-body">
                <table class="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Telefono</th>
                        <th scope="col">Editar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="mb-1" v-for="(client, index) in searchedClients" :key="index">
                            <td> 
                                {{client.id}}
                            </td>
                            <td>{{client.name}}</td>
                            <td>{{client.number_phone}}</td>
                            <td>
                                <ClientEditComponent :user="user" :client_props="client"/>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>
<script>
import { ref,onMounted,computed } from 'vue';

import { useToast } from 'vue-toastification';

import {  useRouter  } from 'vue-router'
import {useStore} from "vuex";

import ClientCreateComponent from '../components/clients/ClientCreateComponent.vue'
import ClientEditComponent from '../components/clients/ClientEditComponent.vue';
import LoadingComponent from '../components/general/LoadingComponent.vue';


export default {
   name: 'client',
   components:{
        ClientCreateComponent,
        ClientEditComponent,
        LoadingComponent
    },
  setup(){
    const router = useRouter()
    const store = useStore();

    const query = ref("");
    const user = ref({});
    
    const v_error_login = computed(()=> store.state.v_error_login)//get data si hay un error en login
    const v_clients = computed(()=> store.state.clients.v_clients)
    const toast = useToast()
    

    const searchedClients = computed(() => {
      return v_clients.value.filter((client) => {
        return (
          client.name
            .toLowerCase()
            .indexOf(query.value.toLowerCase()) != -1
        );
      });
    });

    const traer_clientes = () => {
       store.dispatch('clients/v_actions_clients' )
    };

    onMounted(() => {
        store.dispatch('v_action_login').then(//esperar que verfique y actualizar en localstorage y vuex para luego:
            () => {
                //console.log('hola'+v_error_login.value)
                if (v_error_login.value){//si es verdadero el error de login entonces:
                    toast.error('Ingrese sus datos')
                    router.push({name:'login'})//Redirigir a login 
                }
                else {
                    user.value = store.state.v_user;//usuario actual
                    traer_clientes();
                }
            }
        )
       
    })

    

    return {
        searchedClients,
        user,
        query,
        traer_clientes,
        v_loading_login:computed(()=> store.state.v_loading_login),//loading
        v_clients,
        };
  }
}
</script>
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>