<template>
    <button @click="toggle_state_modal" class="btn btn-success"> Crear una categoria</button>
    <div :class="!state_modal?'d-none':'d-block bg-modal'" class="modal">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Creación de un categoria</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="toggle_state_modal"></button>
            </div>
            <div class="modal-body" style="text-align:start">
                <form>
                    <!-- <pre>
                        {{category}}
                    </pre> -->
                    <div class="mb-3">
                        <label for="recipient-name" class="col-form-label">titulo:</label>
                        <input required v-model="category.title" type="text" class="form-control" >
                    </div>
                    <div class="mb-3">
                        <label for="recipient-name" class="col-form-label">code:</label>
                        <input required v-model="category.code" type="text" class="form-control" >
                    </div>
                    <div class="mb-3">
                        <label for="recipient-name" class="col-form-label">Categoria padre:</label>
                        <input required v-model="category.parent_id" type="text" class="form-control" >
                    </div>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" @click="toggle_state_modal" class="btn btn-secondary">Cerrar</button>
                <button type="button" @click="create_category" class="btn btn-primary">Guardar categoria</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>

import { useToast } from "vue-toastification";
import { computed, ref ,onMounted } from 'vue';
import { useStore } from "vuex";
import axios from 'axios';

    export default {
        name:'categoryCreateComponent',
        props:['category_id'],
        setup(props){
            const store = useStore();
            const state_modal = ref(false);
            const toast = useToast();//usar toast
            const v_user = computed(()=>store.state.v_user)
            const v_parent_category = computed(()=>store.state.categories.v_parent_category)//padre del categoryo
            const category = ref({
                title:'',
                code:'',
                parent_id:null,
                
            });
            
            onMounted(()=>console.log(props.category_id))
            // onUpdated(()=>{
            //     category.value.parent = props.category_parent;
            //     if(props.category_parent)
            //     category.value.parent_id = props.category_parent.id;               
            // })
            

            const toggle_state_modal = () => {
                state_modal.value = !state_modal.value;
            }

            function clear_inputs(){
                category.value = {
                    title:'',
                    code:'',
                    parent_id:'',
                    
                }
            }

            const create_category = () => {
                
                //category.value.parent_id = props.category_id

                console.log(v_user.value)
                let config = {
                    headers: {
                    'Authorization': 'Bearer ' + v_user.value.accessToken,
                    'Accept':'application/json'
                    }
                }
                axios.post(store.state.v_url_global+'/categories/create',category.value,config).then((response) => {
                    console.log(response.data)
                    //category.value = response.data
                    toast.success('Creado con exito')
                    store.dispatch('categories/v_actions_categories')
                    clear_inputs();
                }).catch((error)=>{
                    console.log(error)
                    toast.error('Ingrese los datos correctamente')
                })
            }

            return {
                v_parent_category,
                category,
                state_modal,
                toggle_state_modal,
                create_category,
            }
        }
    }
</script>
<style>
.bg-modal{
    background: #00000066;
}

</style>