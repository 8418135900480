import axios from "axios";

export const products = {
    namespaced: true,
    state: () => ({
        v_products:[],  
        v_parent_product:null,
    }),

   

    mutations: {
        // setName( state, name ){
        //     state.form.name = name;
        // }
       
        V_PRODUCTS(state, data){
            state.v_products = data
        }
    },
    actions: {
        // setName( { commit }, data ){
        //     commit( 'setName', data );
        // }
        v_actions_products({ commit,rootState}){
            //console.log('vuex product')
            rootState.v_loading_login = {status:true,message:'Productos'} 
            let config = {
                headers: {
                'Authorization': 'Bearer ' + rootState.v_user.accessToken,
                'Accept':'application/json'
                }}

            return axios.get(rootState.v_url_global+'/products',config)
              .then(response => {
                rootState.v_loading_login = {status:false,message:''} 
                commit('V_PRODUCTS',response.data)
                })
              .catch(e=>console.log(e));
           
          },
    },
    getters:{
        v_parent_products( state ){
             let arr_productsss = [];
             arr_productsss = state.v_products.filter((product)=>{
                 return (product.parent == null)
             })
             //console.log(arr_productsss)
            return arr_productsss
        },

        v_children_products( state ){
            let arr_productsss = [];
            arr_productsss = state.v_products.filter((product)=>{
                return (product.parent !== null)
            })
            //console.log(arr_productsss)
           return arr_productsss
       },
    }
}