import axios from "axios";

export const turns = {
    namespaced: true,
    state: () => ({
        v_turns:[],  
        
    }),

   

    mutations: {
        // setName( state, name ){
        //     state.form.name = name;
        // }
        V_TURNS(state, data){
            state.v_turns = data
        }
    },
    actions: {
        // setName( { commit }, data ){
        //     commit( 'setName', data );
        // }
        v_actions_turns({ commit,rootState}){
            //console.log(rootState)
            rootState.v_loading_login = {status:true,message:'turnos'} 
            let config = {
                headers: {
                'Authorization': 'Bearer ' + rootState.v_user.accessToken,
                'Accept':'application/json'
                }}

            return axios.get(rootState.v_url_global+'/turns',config)
              .then(response => {
                rootState.v_loading_login = {status:false,message:''} 
                commit('V_TURNS',response.data)})
              .catch(e=>console.log(e));
           
          },
    },
    getters:{
       
    }
}