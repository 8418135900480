import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from "./store";
import VueAxios from 'vue-axios'

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";


import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"


const options = {
    // You can set your default options here
};

const app = createApp(App).use(router).use(store)



//createApp(App).mount('#app')//por  defecto  de vue

app.mount('#app')
app.use(VueAxios, axios)

app.use(Toast, options);


