<template>
    <button @click="toggle_state_modal" class="btn btn-outline-success"><img src="../../assets/square-pen.svg" width="15" alt=""></button>
    <div :class="!state_modal?'d-none':'d-block bg-modal'" class="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Editar: {{category.title}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="toggle_state_modal"></button>
                </div>
                <div class="modal-body">
                     <!-- <pre>
                        {{category_props}}
                    </pre>  -->
                    <form>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Titulo:</label>
                            <input v-model="category.title" type="text" class="form-control" >
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Code:</label>
                            <input v-model="category.code" type="text" class="form-control">
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Categoria padre:</label>
                            <input v-model="category.parent_id" type="number" class="form-control" >
                        </div>
                    </form>
                    <hr>
                    <CategoryCreateComponent :category_id="category.id"/>
                </div>
                <div class="modal-footer">
                    <button type="button" @click="toggle_state_modal" class="btn btn-secondary">Cerrar</button>
                    <button type="button" @click="edit_category" class="btn btn-primary">Editar categoria</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { useToast } from "vue-toastification";
import { ref,onMounted,onUpdated } from 'vue';
import { useStore } from "vuex";
import axios from 'axios';
import CategoryCreateComponent from "./CategoryCreateComponent.vue";



    export default {
        
        name:'CategoryEditComponent',
        components:{
            CategoryCreateComponent,
        },

        props: ['user','category_props'],
        setup(props){
            const store = useStore()
            const state_modal = ref(false);
            const toast = useToast();//usar toast
            const category = ref({
                id:null,
                name:'',
                description:'',
                amount:'',
                amount_suggested:'',
                subcategories:[],
            });

            const toggle_state_modal = () => {
                state_modal.value = !state_modal.value;
            }

            function update_categories(){
               store.dispatch('categories/v_actions_categories')
            }
            
            const edit_category = () => {
                console.log(props.user)
                let config = {
                    headers: {
                    'Authorization': 'Bearer ' + props.user.accessToken,
                    'Accept':'application/json'
                    }
                }
                axios.patch(store.state.v_url_global+'/categories/'+category.value.id,category.value,config).then((response) => {
                    console.log(response.data)
                    //category.value = response.data
                    store.dispatch('categories/v_actions_categories')
                    toast.success('Categoria editada')
                    
                }).catch((error)=>{
                    console.log(error)
                    toast.error(error)
                })
            }

          
            onUpdated(()=>category.value = props.category_props)
            onMounted(()=>{
                category.value = props.category_props
            })

            return {
                
                category,
                state_modal,
                toggle_state_modal,
                edit_category,
                update_categories,
                
            }
        }
    }
</script>
<style>
.bg-modal{
    background: #00000066;
}

</style>