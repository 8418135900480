<template>
    <button :disabled="disabled" @click="toggle_state_modal"> abrir turno</button>
    <div :class="!state_modal?'d-none':'d-block bg-modal'" class="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Abrir turno : {{user.user.name}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="toggle_state_modal"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-floating mb-3">
                            <input v-model="turn.fund_cash" type="text" class="form-control" placeholder="Monto" autocomplete="on">
                            <label for="floatingInput">Fondos</label>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" @click="toggle_state_modal" class="btn btn-secondary">Cerrar</button>
                    <button type="button" @click="create_turn" class="btn btn-primary">Abrir Turno</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {ref} from 'vue'
import axios from 'axios';
import { useStore } from 'vuex';

export default {
    name:'turnCreate',
    props:['disabled','user'],
    setup(props){
        const store = useStore()
        const state_modal = ref(false);
        const turn = ref({
            fund_cash:null,
            date_open:null,
            date_close:null,
            cash_close:null,
            user_id:null,
        })

        function toggle_state_modal(){
            state_modal.value = !state_modal.value
        }

        function create_turn(){
            console.log('dd');
            console.log(props.user)
            let config = {
                headers: {
                'Authorization': 'Bearer ' + props.user.accessToken,
                'Accept':'application/json'
                }
            }
            axios.post(store.state.v_url_global+'/turns/create',{
                fund_cash:turn.value.fund_cash,
                date_open:new window.Date(),
                user_id:props.user.user.id,
            },config).then((response) => {
                console.log(response.data)
                store.dispatch('turns/v_actions_turns' )
                toggle_state_modal()
                //client.value = response.data
                
            }).catch((error)=>{
                console.log(error)
            })
        }
        
        return{
            create_turn,
            toggle_state_modal,
            state_modal,
            turn,

        }
    }
}


</script>