import axios from "axios";



export const clients = {
    namespaced: true,
    state: () => ({
        v_clients:[],
        
    }),

    actions: {
        // setName( { commit }, data ){
        //     commit( 'setName', data );
        // }
        v_actions_clients({commit,rootState}){
            //const router = useRouter()
            rootState.v_loading_login = {status:true,message:'clientes'} 
            //console.log(rootState)
            let config = {
                headers: {
                'Authorization': 'Bearer ' + rootState.v_user.accessToken,
                'Accept':'application/json'
                }}

            return axios.get(rootState.v_url_global+'/clients',config)
              .then(response => {
                rootState.v_loading_login = {status:false,message:''} 
                commit('V_CLIENTS',response.data)})
              .catch(e=>{
                console.log('error en '+e)
                rootState.v_loading_login = {status:false,message:''}
              });
           
          },
    },

    mutations: {
        V_CLIENTS(state, data){
            state.v_clients = data
            // if (state.v_clients.length > 1){
            //     console.log('si hay  clientes'+state.v_clients.length)
            // }
            // else{
            //     console.log('no hay clientes'+state.v_clients.length)
            // }
            
        }
        // setName( state, name ){
        //     state.form.name = name;
        // }
    },
    getters:{
        get_search_id: (state) => (id) => {
            return state.v_clients.find(client => client.id === id)
          }
    }
}