import axios from "axios";

export const categories = {
    namespaced: true,
    state: () => ({
        v_categories:[],  
        v_parent_category:null,
    }),
    mutations: {
        // setName( state, name ){
        //     state.form.name = name;
        // }
       
        V_CATEGORIES(state, data){
            state.v_categories = data
        }
    },
    actions: {
        // setName( { commit }, data ){
        //     commit( 'setName', data );
        // }
        v_actions_categories({ commit,rootState}){
            //console.log('vuex product')
            rootState.v_loading_login = {status:true,message:'Categorias'} 
            let config = {
                headers: {
                'Authorization': 'Bearer ' + rootState.v_user.accessToken,
                'Accept':'application/json'
                }}

            return axios.get(rootState.v_url_global+'/categories',config)
              .then(response => {
                rootState.v_loading_login = {status:false,message:''}
                commit('V_CATEGORIES',response.data)
                })
              .catch(e=>console.log(e));           
          },
    },
    getters:{
        v_parent_categories( state ){
             let arr_categoriesss = [];
             arr_categoriesss = state.v_categories.filter((category)=>{
                 return (category.parent_id == null)
             })
             //console.log(arr_categoriesss)
            return arr_categoriesss
        },

        v_parent_second_categories(state,getters){

            let arr_second = []
            let arr_first = getters.v_parent_categories;
            
            arr_second = arr_first
            
            return arr_first,arr_second;
        },


        v_children_categories( state ){
            let arr_categoriesss = [];
            arr_categoriesss = state.v_categories.filter((category)=>{
                return (category.parent_id !== null)
            })
            //console.log(arr_categoriesss)
           return arr_categoriesss
       },
    }
}