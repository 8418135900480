<template>
    
    <!-- <draggable 
    v-model="myArray" 
    group="people" 
    @start="drag=true" 
    @end="drag=false" 
    item-key="id">
    <template #item="{element}">
        <div>{{element.name}}</div>
    </template>
    </draggable> -->
   
    <div class="container">
        <div class="card">
            <div class="card-header header-trello">
                <h1>
                    Movimientos de orden de servicio
                </h1>
                <div class=" btns-counter">
                    <p><b>fecha  de inicio :</b> 12/24/2022</p>
                    
                    <p><b>Tiempo trancurrido :</b> 24:00 minutos</p>
                    <button class="btn btn-success text-center">Iniciar contador</button> <br>
                    <button class="btn btn-success text-center">Finalizar contador</button>
                </div>
                <div class="pay-input">
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">Cuota</label>
                    </div>
                    <button class="btn btn-primary"> pagar cuenta</button>
                </div>
            </div>
            <div class="card-body">
                <div>
                    <label>Selecionar cliente:</label>
                    <v-select v-model="select_client" :options="v_clients" :reduce="(option) => option " label="name">
                    </v-select>
                </div>
                <hr>
                <div>
                    
                    <h5>Seleciona el caso en la  siguiente lista:</h5>
                    <ul>
                        <li class="my-2" v-for="voucher_product in searchedVouchers" :key="voucher_product.id">
                            
                            <button class="btn btn-sm btn-primary" @click="load_voucher(voucher_product)">Seleccionar</button>
                            {{voucher_product.case}} 
                        </li>
                    </ul>
                </div>
                <hr>

                <div class="row">
                    <div class="col-4">
                        <h3>Lista de servicios</h3>
                        <draggable
                        class="list-group"
                        :list="list1"
                        group="people"
                        @change="log"
                        itemKey="name"
                        >
                            <template #item="{ element, index }">
                                <div class="list-group-item">{{ element.name }} {{ index }}
                                    <!-- <ul>
                                        <li>
                                            Sub producto
                                        </li>
                                    </ul> -->
                                </div>
                            </template>
                        </draggable>

                        <!-- <pre>
                            {{list1}}
                        </pre> -->
                    </div>
                    <div class="col-4">
                        <h3>En progreso</h3>
                        <draggable
                            class="list-group"
                            :list="list2"
                            group="people"
                            @change="log"
                            itemKey="name"
                        >
                            <template #item="{ element, index }">
                            <div class="list-group-item">{{ element.name }} {{ index }}</div>
                            </template>
                        </draggable>
                        <!-- <pre>
                        {{list2}}
                        </pre> -->
                    </div>
                    <div class="col-4">
                        <h3>Terminados</h3>
                        <draggable
                            class="list-group"
                            :list="list3"
                            group="people"
                            @change="log"
                            itemKey="name"
                        >
                            <template #item="{ element, index }">
                            <div class="list-group-item">{{ element.name }} {{ index }}</div>
                            </template>
                        </draggable>
                        <!-- <pre>
                        {{list2}}
                        </pre> -->
                    </div>

                    <div class="d-none">
                        <h1>mostrar array para captura todos los pivot</h1>
                        <b>Lista: 1</b>
                        <ul>
                            <li v-for="list in list1" :key="list.id">
                                {{list.pivot}}
                            </li>
                        </ul>
                        <b>Lista: 2</b>
                        <ul>
                            <li v-for="list in list2" :key="list.id">
                                {{list.pivot}}
                            </li>
                        </ul>
                        <b>Lista: 3</b>
                        <ul>
                            <li v-for="list in list3" :key="list.id">
                                {{list.pivot}}
                            </li>
                        </ul>
                    </div>
                    <div class="d-none">
                        {{select_voucher.id}}
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import { ref,onMounted,computed } from 'vue';
import draggable from 'vuedraggable'
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import {useRouter} from 'vue-router'
import axios from 'axios';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

     export default {
        components: {
            draggable,
            vSelect,
        },
        setup(){
            const store = useStore()
            const toast = useToast()
            const router = useRouter()

            const v_clients =  computed(() => store.state.clients.v_clients)//get traer clientes 
            const v_vouchers = computed(()=>store.state.vouchers.v_vouchers)//get traer vouchers
            const v_error_login = computed(()=> store.state.v_error_login)//get data si hay un error en login

            const query = ref("");//para buscar cliente
            const query_voucher = ref('')//buscar voucher

            const v_user =  computed(() => store.state.v_user)//get traer clientes

            const select_client = ref(null)
            const select_voucher = ref({})//for list in the trello

            const drag = ref(false)
            const list = ref([])
            const myArray = ref([
                {id:'1',name:'productos de ejemplo'},
                {id:'2',name:'productos de ejemplo'},
            ]);
            const list1 = ref([
                { name: "productos de ejemplo", id: 1 },
                { name: "productos de ejemplo", id: 2 },
                { name: "productos de ejemplo", id: 3 },
                { name: "productos de ejemplo", id: 4 }
            ])
            const list2 = ref([
            { name: "productos de ejemplo", id: 5 },
                { name: "productos de ejemplo", id: 6 },
                { name: "productos de ejemplo", id: 7 }
            ])
            const list3 = ref([
                { name: "productos de ejemplo", id: 8 },
                { name: "productos de ejemplo", id: 9 },
                { name: "productos de ejemplo", id: 10 }
            ])
            const add = () => {
                list.value.push({name:"juan"})
            }
            const replace = () => {
                list.value({name:"juan"})
            }
            const log = (evt) => {
                window.console.log(evt);
                console.log("actulizando datos")

                

                console.log(v_user.value)
                let config = {
                    headers: {
                    'Authorization': 'Bearer ' + v_user.value.accessToken,
                    'Accept':'application/json'
                    }
                }

                const body_1 = {
                    list_1:JSON.stringify(list1.value),
                    list_2:JSON.stringify(list2.value),
                    list_3:JSON.stringify(list3.value),
                }

                axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
                axios.post(store.state.v_url_global+'/status-update',body_1,config).then((response) => {
                    console.log(response.data)
                    //product.value = response.data
                    
                    
                    store.dispatch('vouchers/v_actions_vouchers').then(()=>{
                        v_vouchers.value.map(product => {
                            if (product.id === select_voucher.value.id){
                                load_voucher(product)        
                            }
                        })
                        
                    })
                    toast.success('actualizando estados de productos ')

                    
                    
                }).catch((error)=>{
                    console.log(error)
                    toast.error(error)
                })



            }

            onMounted(() => {
                store.dispatch('v_action_login').then(//esperar que verfique y actualizar en localstorage y vuex para luego:
                    () => {
                        //console.log('hola'+v_error_login.value)
                        if (v_error_login.value){//si es verdadero el error de login entonces:
                            toast.error('Ingrese sus datos')
                            router.push({name:'login'})//Redirigir a login 
                        }
                        else {
                            store.dispatch('clients/v_actions_clients' )
                            store.dispatch('vouchers/v_actions_vouchers')
                            store.dispatch('products/v_actions_products')
                            store.dispatch('v_actions_users')
                            store.dispatch('categories/v_actions_categories')
                        }
                    }
                )
            });

            const vouchers_to_client = computed(() => {
                if (select_client.value !== null){
                    return v_vouchers.value.slice().filter(voucher => voucher.client_id == select_client.value.id)
                }else{
                    return v_vouchers.value.slice()  
                }
            })

            // const products_start = computed(()=>{
                
            // })
            const searchedVouchers = computed(() => {
            return vouchers_to_client.value.filter((voucher) => {
                return (
                    voucher.date
                        .toLowerCase()
                        .indexOf(query_voucher.value.toLowerCase()) != -1
                    );
                });
            });  
            const load_voucher = (voucher) =>{

                console.log('cargando voucherss')
                select_voucher.value = voucher;

                list1.value = select_voucher.value.products.filter(product => product.pivot.status == 'start')
                list2.value = select_voucher.value.products.filter(product => product.pivot.status == 'process')
                list3.value = select_voucher.value.products.filter(product => product.pivot.status == 'end')

                console.log(select_voucher.value)
            }
            return{
                //products_start,
                v_user,
                load_voucher,//load voucher for to trello
                searchedVouchers,
                select_voucher,
                v_clients,
                v_vouchers,
                v_error_login,
                query,
                query_voucher,
                select_client,
                add,
                replace,
                log,
                list1,
                list2,
                list3,
                drag,
                myArray
            }
        }
     }
</script>
<style lang="scss" scoped>

.header-trello{
    display: grid;
    grid-template-columns:1fr 1fr 1fr;
    .btns-counter{
        text-align: center;
        button{
            margin: 1px;
        }
    }
    .pay-input{
       
    }
}
</style>
