<template>
    <!-- <h1>
        Products
    </h1> -->
    <!-- <input v-model="query" type="text">
    <p v-html="query"></p> -->
    <!-- <button @click="traer_productos"> Traer o actualizar productos</button> -->
   <transition>
        <LoadingComponent v-if="v_loading_login.status" :message="v_loading_login.message"></LoadingComponent>  
    </transition>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h1>
                    Productos
                </h1>
                <!-- <p v-html="query"></p> -->
                <div class="input-group mb-3">
                    <input  v-model="query" type="text" class="form-control" placeholder="Buscar">
                    <span class="input-group-text" id="basic-addon1">
                        <ProductCreateComponent 
                        :btn_name="'Crear producto'" 
                        :parent="false"
                        :product_id="null"/>
                    </span>
                </div>
                
            </div>
            <div class="card-body">
                <table class="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Descripción</th>
                        
                        <th scope="col">Editar</th>
                        <th scope="col">Borrar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="mb-1" v-for="(product, index) in searchedProducts" :key="index">
                            <td> 
                                {{product.id}}
                            </td>
                            <td>{{product.name}}</td>
                            <td>{{product.description}}</td>
                            
                            <td>
                                <ProductEditComponent :user="user" :product_props="product"/>
                            </td>
                            <td>
                                <ProductDeleteComponent :user="user" :product_props="product"/>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { ref,onMounted,computed } from 'vue';
import {  useRouter  } from 'vue-router'
import {useStore} from "vuex";
import { useToast } from 'vue-toastification';

import ProductCreateComponent from '../components/products/ProductCreateComponent.vue'
import ProductEditComponent from '../components/products/ProductEditComponent.vue'
import ProductDeleteComponent from '../components/products/ProductDeleteComponent.vue';
import LoadingComponent from '../components/general/LoadingComponent.vue';

export default {
  name: 'Product',
  components:{
    ProductCreateComponent,
    ProductEditComponent,
    ProductDeleteComponent,
    LoadingComponent,
  },
  setup(){
    const query = ref("");
    
    const user = ref({});
    
    const toast = useToast();
    const router = useRouter()
    const store = useStore()

    const v_products = computed(()=> store.state.products.v_products)//get data products
    const v_error_login = computed(()=> store.state.v_error_login)//get data si hay un error en login
    
    const searchedProducts = computed(() => {
        let arr_products = [];
        arr_products = store.getters['products/v_parent_products']
        //arr_products = store.getters['products/v_parent_products'](params)
        //console.log(arr_products)
      return arr_products.filter((product) => {
        return (
          product.name
            .toLowerCase()
            .indexOf(query.value.toLowerCase()) != -1
        );
      });
    });

    const traer_productos = () => {
        store.dispatch('products/v_actions_products' )
    };

    

    onMounted(() => {
        
        store.dispatch('v_action_login').then(//esperar que verfique y actualizar en localstorage y vuex para luego:
            () => {
                
                if (v_error_login.value){//si es verdadero el error de login entonces:
                    toast.error('Ingrese sus datos')
                    router.push({name:'login'})//Redirigir a login 
                }
                else {
                    user.value = store.state.v_user;//usuario actual
                    traer_productos()
                    store.dispatch('categories/v_actions_categories')//traer categorias para cargar la seccion de id_categoria de un producto
                }
            }
        )
    });



    return {
        
        searchedProducts,
        user,
        query,
        traer_productos,
        v_products,
        v_loading_login:computed(()=> store.state.v_loading_login),//loading
        };
  }
}
</script>


<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>