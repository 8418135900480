
<template>
<div class="modal-loading">
   <button class="btn btn-primary" type="button" disabled>
    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    Cargando {{message}}...
   </button>
    
</div>    
</template>

<script>
export default{
    props:['message'],
    setup(){

        return{

        }
    }
}


</script>

<style>
.modal-loading{
    top:0;
    left:0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 99999999999999;
    color: white;
    display: grid;
    justify-content: center;
    align-items: center;

}
</style>