import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login_v from '../views/Login_v.vue'
import Trello from '../views/Trello.vue'
import Client from '../views/Client.vue'
import Category from '../views/Category.vue'
import Product from '../views/Product.vue'
import Voucher from '../views/Voucher.vue'
import Cash from '../views/Cash.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path:'/login',
    name:'login',
    component:Login_v,
  },
  {
    path:'/trello',
    name:'trello',
    component:Trello,
  },
  {
    path:'/client',
    name:'client',
    component:Client,
  },
  {
    path:'/category',
    name:'category',
    component:Category,
  },
  {
    path:'/product',
    name:'product',
    component:Product,
  },
  {
    path:'/voucher',
    name:'voucher',
    component:Voucher,
  },
  {
    path:'/cash',
    name:'cash',
    component:Cash,
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router