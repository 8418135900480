<template>
    <button @click="toggle_state_modal" class="btn btn-success"> Crear un cliente</button>
    <div :class="!state_modal?'d-none':'d-block bg-modal'" class="modal">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Creación de un cliente</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="toggle_state_modal"></button>
            </div>
            <div class="modal-body" style="text-align:start">
                <form>
                    <div class="mb-3">
                        <label for="recipient-name" class="col-form-label">Nombre:</label>
                        <input v-model="client.name" type="text" class="form-control" >
                    </div>
                    <div class="mb-3">
                        <label for="recipient-name" class="col-form-label">Apellidos:</label>
                        <input v-model="client.last_name" type="text" class="form-control" >
                    </div>
                    <div class="mb-3">
                        <label for="recipient-name" class="col-form-label">Tipo de documento:</label>
                        <select v-model="client.type_doc" class="form-select" aria-label="Default select example">
                            <option selected>Tipo de documento</option>
                            <option value="LIBRETA ELECTORAL O DNI">LIBRETA ELECTORAL O DNI</option>
                            <option value="CARNET DE EXTRANJERIA">CARNET DE EXTRANJERIA</option>
                            <option value="PASAPORTE">PASAPORTE</option>
                            <option value="OTROS">OTROS</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="recipient-name" class="col-form-label">Numero de documento:</label>
                        <input v-model="client.number_doc" type="text" class="form-control" >
                    </div>
                    <div class="mb-3">
                        <label for="recipient-name" class="col-form-label">Tipo de cliente:</label>
                        <select v-model="client.type_client" class="form-select" aria-label="Default select example">
                            <option selected disabled>Tipo de cliente</option>
                            <option value="normal">Normales</option>
                            <option value="regulares">Regulares</option>
                            <option value="vip">VIP</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="recipient-name" class="col-form-label">Dirección:</label>
                        <input v-model="client.address" type="text" class="form-control" >
                    </div>
                    <div class="mb-3">
                        <label for="recipient-name" class="col-form-label">RUC:</label>
                        <input v-model="client.RUC" type="text" class="form-control" >
                    </div>
                    <div class="mb-3">
                        <label for="recipient-name" class="col-form-label">Email:</label>
                        <input v-model="client.email" type="text" class="form-control" >
                    </div>
                    <div class="mb-3">
                        <label for="recipient-name" class="col-form-label">Numero telefono/celular:</label>
                        <input v-model="client.number_phone" type="text" class="form-control" >
                    </div>
                    
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" @click="toggle_state_modal" class="btn btn-secondary">Cerrar</button>
                <button type="button" @click="create_client" class="btn btn-primary">Guardar cliente</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';
import axios from 'axios';
import { useToast } from "vue-toastification";
import { useStore } from 'vuex';

export default {
    name:'ClientCreateComponent',
    props: ['user'],
    setup(props){
        const store = useStore()
        const state_modal = ref(false);
        const toast = useToast();//usar toast
        const client = ref({
            name:'',
            last_name:'',
            type_doc:'LIBRETA ELECTORAL O DNI',
            number_doc:'',
            type_client:'normal',
            address:'',
            RUC:'',
            email:'',
            number_phone:'',

        });

        const toggle_state_modal = () => {
            state_modal.value = !state_modal.value;
        }

        function clear_inputs(){
            client.value = {
                name:'',
                last_name:'',
                type_doc:'',
                number_doc:'',
                type_client:'',
                address:'',
                RUC:'',
                email:'',
                number_phone:'',
            }
        }

        const create_client = () => {
            console.log(props.user)
            let config = {
                headers: {
                'Authorization': 'Bearer ' + props.user.accessToken,
                'Accept':'application/json'
                }
            }
            axios.post(store.state.v_url_global+'/clients/create',client.value,config).then((response) => {
                console.log(response.data)
                //client.value = response.data
                clear_inputs();
                store.dispatch('clients/v_actions_clients' )
                toast.success('Cliente creato con exito')
            }).catch((error)=>{
                console.log(error)
                toast.error('Ingrese los datos')
            })
        }

        return {
            client,
            state_modal,
            toggle_state_modal,
            create_client,
        }
    }
}
</script>
<style>
.bg-modal{
    background: #00000066;
}

</style>