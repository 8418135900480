<template>
    <div class="card mt-3">
        <table class="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Nombre</th>
                        
                        <!-- <th scope="col">Ver</th> -->
                        <th scope="col">Editar</th>
                        <th scope="col">Borrar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr  class="mb-1" v-for="(product, index) in subproducts" :key="index">
                            <td> 
                               {{product.id}}
                            </td>
                            <td>{{product.name}}</td>
                            
                            <!-- <td>
                                <button class="btn btn-sm btn-outline-primary">
                                    <img src="../../../assets/eye.svg" width="12" alt="">
                                </button>
                            </td> -->
                            <td>
                                <SubproductEditComponent :user="v_user" :product_props="product" ></SubproductEditComponent>
                                <!-- <button class="btn btn-sm btn-outline-success">
                                    <img src="../../../assets/square-pen.svg" width="12" alt="">
                                </button> -->
                            </td>
                            <td>
                                <SubproductDeleteComponent :user="v_user" :product_props="product"/>
                            </td>
                         
                        </tr>
                        
                    </tbody>
                </table>
        
    </div>
</template>
<script>
    import SubproductEditComponent from './SubproductEditComponent.vue';
    import SubproductDeleteComponent from './SubproductDeleteComponent.vue';
    import { useStore } from 'vuex';
    import { computed} from 'vue'

    export default{
        components:{
            SubproductEditComponent,
            SubproductDeleteComponent
        },
        props:['subproducts'],
        setup(){
            const store = useStore()
            const v_user = computed(()=>store.state.v_user)
            return{
                v_user
            }
        }
    }

</script>
<style>
    
</style>