import { createStore } from "vuex";
import axios from "axios";
import {clients} from './modules/clients'
import {turns} from './modules/turns'
import { vouchers } from "./modules/vouchers";
import { products } from "./modules/products";
import { categories } from "./modules/categories";


export default createStore({
  
 modules:{
  clients,
  turns,
  vouchers,
  products,
  categories,
 },
  state: {
    v_user: {},
    v_login:{
      email:null,
      password:null,
    },
    v_users:[],
    v_url_global:'https://api-blason.webencusco.com/api',//'https://api-blason.webencusco.com/api'//'http://127.0.0.1:8000/api'
    v_status_api:'',
    v_error_login:false,//para ver si hay un error cuando hacemos la peticion
    v_loading_login:{
      status:false,
      message:''
    },

    
  },
  mutations: {
    v_user_m(state){
      return state.v_user
    },
    V_USERS(state, data_users) {
      state.v_users = data_users
    },
    V_LOGOUT(state){
      state.v_user = {}
      state.v_login = {
        email:'',
        password:''
      } 
      localStorage.removeItem('UserLogin');
    },
    V_LOGIN(state,data_login){
      state.v_user = data_login;
      localStorage.setItem('UserLogin', JSON.stringify(data_login));
      state.v_status_api = 'success'
    }
  },  
  actions:{
    v_actions_users({ commit,state}){
      state.v_loading_login = {status:true,message:'Usuarios'} 
      let config = {
        headers: {
        'Authorization': 'Bearer ' +  state.v_user.accessToken,
        'Accept':'application/json'
          }
      }
      return axios.get(state.v_url_global+'/users',config)
        .then(response => {
          state.v_loading_login = {status:false,message:''} 
          commit('V_USERS',response.data)})
        .catch();
    },
    //yourAction ({commit,state}, ac_query){
    v_action_login({commit,state}){

      let user = JSON.parse(localStorage.getItem('UserLogin'));
      console.log(user)
      state.v_loading_login = {status:true,message:'accesos'} 
     
      if (user && user.accessToken){//y hay un usuario en localstorage hacer la consulta si su token sirve

        let config = {
          headers: {
          'Authorization': 'Bearer ' + user.accessToken,
          'Accept':'application/json'
            }
        }

        return axios.get(state.v_url_global+'/check',config)
          .then(response => {
            console.log('autorizado'+response.data)
            state.v_user = JSON.parse(localStorage.getItem('UserLogin'));
            state.v_loading_login = {status:false} //finish  loading
            state.v_error_login = false //no  hay error
          })
          .catch(e => {
            console.log('no se puedo conectar con el  token'+e)
            localStorage.removeItem('UserLogin');
            state.v_loading_login = {status:false} //finish  loading
            state.v_error_login = true //notificar que no se puedo ingresar
            }
          );
      }
      else{//caso contrario hacer una consulta con nuevo datos
        return axios.post(state.v_url_global+'/login',{
          email:state.v_login.email,
          password:state.v_login.password
          
        })
          .then(response => {
            commit('V_LOGIN',response.data)
            state.v_loading_login = {status:false} //finish  loading
            state.v_error_login = false//falso error
          })  
          .catch(e => { // si hay un error cualquiera mostrar en consola
            console.log('v_error:'+e)
            state.v_status_api = 'error en login'
            state.v_loading_login = {status:false} //finish  loading
            state.v_error_login = true //notificar que no se puedo ingresar 
            
          });
      }
    },
    v_action_logout({commit,state}){//cerrar sesion de usuario
      let config = {
        headers: {
        'Authorization': 'Bearer ' + state.v_user.accessToken,
        'Accept':'application/json'
        }
    }

      return axios.get(state.v_url_global+'/logout',config)
        .then(response => {
          commit('V_LOGOUT',null)
          console.log('cerrar sesion'+response);
          localStorage.removeItem('UserLogin');//borrar datos de la cache del  navegador
          state.v_error_login = false//falso error
        })
        .catch();
    },

    v_test(data){
      console.log(data)
    }
  },
  getters:{

  }
});