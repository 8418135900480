<template>
  <button @click="toggle_state_modal" class="btn btn-success btn-sm">
    ver
  </button>
  <div :class="!state_modal ? 'd-none' : 'd-block bg-modal'" class="modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Comprobante </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="toggle_state_modal"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row d-flex justify-content-center">
              <div class="col-md-12">
                <div class="card">
                  <div class="d-flex flex-row p-2">
                    <!--  <img src="https://i.imgur.com/vzlPPh3.png" width="48"> -->
                    <div
                      onclick="javascript:window.print();"
                      class="d-flex flex-column"
                    >
                      <span class="font-weight-bold">Comprobante: </span>
                      <small>INV-00{{ voucher.id }}</small>
                    </div>
                  </div>
                  <hr />
                  <div class="table-responsive p-2">
                    <table class="table table-borderless">
                      <tbody>
                        <tr class="add">
                          <td>De:</td>
                          <td>Para:</td>
                        </tr>
                        <tr class="content">
                          <td v-if="user_voucher" class="font-weight-bold">
                            {{user_voucher.name}} <br />
                            
                            {{user_voucher.email}}
                          </td>
                          <td class="font-weight-bold">
                            {{ client.name }} {{client.last_name}} <br />
                            DNI: {{client.number_doc}} <br />Cel: {{ client.email }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr />
                  <div class="products p-2">
                    <table class="table table-striped">
                      <!-- <pre>
                        {{voucher}}
                      </pre> -->
                      <tbody v-if="voucher.products">
                        <tr class="add">
                          <th class="col">Nombre</th>
                          <th class="col">Descripción</th>
                          <th class="col">Subproductos</th>
                          <th class="col">Precio fijo</th>
                          <th class="col">Precio suferido</th>
                          <th class="col">Total</th>
                        </tr>
                        <tr v-for="product in voucher.products" :key="product.id">
                          <td>{{product.name}}</td>
                          <td>{{product.description}} subproductos view</td>
                          <td>{{product}}</td>
                          <td>{{product.amount}}</td>
                          <td>{{product.pivot.amount}}</td>
                          <td>{{product.pivot.amount}}</td>
                        </tr>
                       
                      </tbody>
                    </table>
                  </div>
                  <hr />
                  <div class="products p-2">
                    <table class="table table-borderless">
                      <tbody>
                        <tr class="add">
                          
                          <td>total precio fijo</td>
                          <td class="text-center">Total</td>
                        </tr>
                        <tr class="content">
                          
                          <td>s/{{total_fijo}}.00</td>
                          <td class="text-center">s/{{total}}.00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        
          
        </div>

        <div class="modal-footer">
          <button
            type="button"
            @click="toggle_state_modal"
            class="btn btn-secondary"
          >
            Cerrar
          </button>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  props: ["voucher"],
  setup(props) {
    const store = useStore();
    const v_clients = computed(() => store.state.clients.v_clients);
    const v_users = computed(() => store.state.v_users);

    const client = computed(()=>v_clients.value.find(
      (client) => client.id == props.voucher.client_id
    ))

    const total = computed(()=>props.voucher.products.reduce(function (acc, obj) { return acc + obj.pivot.amount; }, 0))
    const total_fijo = computed(()=>props.voucher.products.reduce(function (acc, obj) { return acc + obj.amount; }, 0))

    const user_voucher = computed(() =>v_users.value.find(
      (user) => user.id == props.voucher.user_id
    ))

    const state_modal = ref(false);

    const toggle_state_modal = () => {
      state_modal.value = !state_modal.value;
    };

    return {
      total_fijo,
      total,
      user_voucher,
      client,
      v_clients,
      toggle_state_modal,
      state_modal,
      v_users,
    };
  },
};
</script>