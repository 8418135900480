<template>
    <button @click="toggle_state_modal" class="btn btn-success"> {{btn_name}}</button>
    <div :class="!state_modal?'d-none':'d-block bg-modal'" class="modal">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{btn_name}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="toggle_state_modal"></button>
            </div>
            <div class="modal-body" style="text-align:start">
                <form>
                    <div class="row">
                        <div class="col-12 my-3">
                            <b >Categoria 1er nivel:</b>
                            <v-select v-model="select_cat_first" :options="v_parent_categories" :reduce="(option) => option" label="title">
                            </v-select>
                        </div>
                        <div class="col-12 my-3">
                            <b >Categoria 2do nivel:</b>
                            <v-select v-model="select_cat_second" :options="array_cat_second" :reduce="(option) => option" label="title">
                            </v-select>
                        </div>
                        <div class="col-12 my-3">
                            <b >Categoria 3er nivel:</b>
                            <v-select v-model="select_cat_third" :options="array_cat_third" :reduce="(option) => option" label="title">
                            </v-select>

                        </div>
                    </div>
                    
                   <div v-show="select_cat_third">
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Nombre:</label>
                            <input required v-model="product.name" type="text" class="form-control" >
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Descripción:</label>
                            <textarea required v-model="product.description" type="text" class="form-control" ></textarea>
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Precio fijo:</label>
                            <input required v-model="product.amount" type="number" class="form-control">
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Precio sugerido:</label>
                            <input required v-model="product.amount_suggested" type="number" class="form-control">
                        </div>
                   </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" @click="toggle_state_modal" class="btn btn-secondary">Cerrar</button>
                <button type="button" @click="create_product" class="btn btn-primary">Guardar</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>

import { useToast } from "vue-toastification";
import { computed, ref ,onMounted, watchEffect } from 'vue';
import { useStore } from "vuex";
import axios from 'axios';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

    export default {
        name:'productCreateComponent',
        props:['product_id','btn_name','parent'],
        components:{
            vSelect
        },
        setup(props){
            const store = useStore();
            const state_modal = ref(false);
            const toast = useToast();//usar toast
            const v_user = computed(()=>store.state.v_user)
            const v_parent_product = computed(()=>store.state.products.v_parent_product)//padre del producto
            const product = ref({
                name:'',
                description:'',
                category_id:null,
                amount:null,
                amount_suggested:null,
                parent_id:null,
            });

            const select_cat_first = ref({})//object -> children
            const select_cat_second = ref({})
            const array_cat_second = ref([])

            const select_cat_third = ref(null)
            const array_cat_third = ref({})


            const view_subarrays = () =>{
            if(select_cat_first.value){
                console.log(select_cat_first.value.children)
                array_cat_second.value = select_cat_first.value.children
            }
            else{
                array_cat_second.value = []
                select_cat_second.value = {}
                array_cat_third.value = []
                select_cat_third.value = null
            }

            if(select_cat_second.value){
                console.log(select_cat_second.value.children)
                array_cat_third.value = select_cat_second.value.children
            }else{
                array_cat_third.value = []
                select_cat_third.value = null
            }
            
            if (select_cat_third.value){//Seleccionar el  id de categoria para producto
                product.value.category_id = select_cat_third.value.id
            }

            
            


        }

        watchEffect(() => view_subarrays());



            
            onMounted(()=>console.log(props.product_id))
            // onUpdated(()=>{
            //     product.value.parent = props.product_parent;
            //     if(props.product_parent)
            //     product.value.parent_id = props.product_parent.id;
                                
            // })
            

            const toggle_state_modal = () => {
                state_modal.value = !state_modal.value;
            }

            function clear_inputs(){
                product.value = {
                    name:'',
                    description:'',
                    amount:'',

                    amount_suggested:'',
                }
            }

            const create_product = () => {
                
                product.value.parent_id = props.product_id

                console.log(v_user.value)
                let config = {
                    headers: {
                    'Authorization': 'Bearer ' + v_user.value.accessToken,
                    'Accept':'application/json'
                    }
                }
                axios.post(store.state.v_url_global+'/products/create',product.value,config).then((response) => {
                    console.log(response.data)
                    //product.value = response.data
                    toast.success('Creado con exito')
                    store.dispatch('products/v_actions_products')
                    clear_inputs();
                }).catch((error)=>{
                    console.log(error)
                    toast.error('Ingrese los datos correctamente')
                })
            }

            return {
                select_cat_first,
                select_cat_second,
                array_cat_second,
                select_cat_third,
                array_cat_third,
                v_parent_product,
                product,
                state_modal,
                toggle_state_modal,
                create_product,
                v_categories:computed(()=>store.state.categories.v_categories),
                v_parent_categories:computed(()=>store.getters['categories/v_parent_categories']),
            }
        }
    }
</script>
<style>
.bg-modal{
    background: #00000066;
}

</style>