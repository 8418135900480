<template>
    <transition>
        <LoadingComponent v-if="v_loading_login.status" :message="v_loading_login.message"></LoadingComponent>
    </transition>

    <div class="container">
       <div class="card">
        <div class="card-header">
            <h2>Vouchers</h2>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-4">
                    <div class="card">
                        <div class="card-header">
                            <h5>
                                Clientes
                            </h5>
                            <div class="input-group mb-3">
                                <input  v-model="query" type="text" class="form-control" placeholder="Buscar">
                                <span class="input-group-text" id="basic-addon1"><ClientCreateComponent :user="user"/></span>
                            </div>
                        </div>
                        <div class="card-body">
                            <table class="table table-striped table-sm">
                                <thead>
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Telefono</th>
                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="mb-1" v-for="(client, index) in searchedClients" :key="index" @click="load_client(client)"> 
                                        <td v-if="select_client" :class="select_client.id == client.id?'text-danger':''"> 
                                            {{client.id}}
                                        </td>
                                        <td v-else>
                                            {{client.id}}
                                        </td>

                                        <td>{{client.name}}</td>
                                        <td>{{client.number_phone}}</td>
                                        <td class="d-none">
                                            <ClientEditComponent :user="user" :client_props="client"/>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </div> 
                </div>
                <div class="col-8">
                    <div class="card">
                        <div class="card-header">
                            <span v-if="select_client" >
                            Vouchers de :{{select_client.name}}

                            <div>
                                <VoucherCreateComponentVue :select_client="select_client"/>
                            </div>
                            
                            </span>
                            
                        </div>
                        <div class="card-body">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Caso</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Cliente</th>
                                        <th scope="col">Ver</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="mb-1" v-for="(voucher, index) in searchedVouchers" :key="index">
                                        <td> 
                                            {{voucher.id}}
                                        </td>
                                        <td>{{voucher.case}}</td>
                                        <td>{{voucher.date}}</td> <!--voucher.products--> 
                                        <td>{{get_search_id(voucher.client_id).name}}</td>
                                        <td>
                                            <VoucherViewComponent :voucher="voucher" />
                                        </td>
                                        
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>

    </div>
    
    
    
    
</template>
<script>


import VoucherCreateComponentVue from '../components/vouchers/VoucherCreateComponent.vue';
import {useStore} from "vuex";
import {useRouter} from 'vue-router'
import LoadingComponent from '../components/general/LoadingComponent.vue';
import { useToast } from 'vue-toastification';
import ClientCreateComponent from '../components/clients/ClientCreateComponent.vue'
import ClientEditComponent from '../components/clients/ClientEditComponent.vue';
import VoucherViewComponent from '../components/vouchers/VoucherViewComponent.vue';



import { ref,onMounted,computed } from 'vue';
    export default {
        
        name:'Voucher',
        components:{
            VoucherCreateComponentVue,
            LoadingComponent,
            ClientCreateComponent,
            ClientEditComponent,
            VoucherViewComponent,
        },
        setup(){
            const store = useStore();
            const router = useRouter();
            const v_clients =  computed(() => store.state.clients.v_clients)//get traer clientes 
            const v_vouchers = computed(()=> store.state.vouchers.v_vouchers)//get traer vouchers
            const v_error_login = computed(()=> store.state.v_error_login)//get data si hay un error en login

            const query = ref("");//para buscar cliente
            const query_voucher = ref('')//buscar voucher
            const user = ref();
            const toast = useToast();
            const select_client = ref(null)

            onMounted(() => {
                store.dispatch('v_action_login').then(//esperar que verfique y actualizar en localstorage y vuex para luego:
                    () => {
                        //console.log('hola'+v_error_login.value)
                        if (v_error_login.value){//si es verdadero el error de login entonces:
                            toast.error('Ingrese sus datos')
                            router.push({name:'login'})//Redirigir a login 
                        }
                        else {
                            store.dispatch('clients/v_actions_clients' )
                            store.dispatch('vouchers/v_actions_vouchers')
                            store.dispatch('products/v_actions_products')
                            store.dispatch('v_actions_users')
                            store.dispatch('categories/v_actions_categories')
                            
                            user.value = store.state.v_user;//usuario actual
                        }
                    }
                )
            });

            const get_search_id = (id) => {
                return v_clients.value.find(client => client.id === id)
            }

            const searchedClients = computed(() => {
            return v_clients.value.filter((client) => {
                return (
                    client.name
                        .toLowerCase()
                        .indexOf(query.value.toLowerCase()) != -1
                    );
                });
            });

            const load_client = (user) => {
                console.log(user)
                select_client.value = user
                
            }

            const vouchers_to_client = computed(() => {
                if (select_client.value !== null){
                    return v_vouchers.value.slice().filter(voucher => voucher.client_id == select_client.value.id)    
                }else{
                    return v_vouchers.value.slice()  
                }
            })

            const searchedVouchers = computed(() => {
            return vouchers_to_client.value.filter((voucher) => {
                return (
                    voucher.date
                        .toLowerCase()
                        .indexOf(query_voucher.value.toLowerCase()) != -1
                    );
                });
            });

            

            return {
                query_voucher,//buscar voucher
                select_client,
                load_client,
                query,
                searchedClients,
                searchedVouchers,//buscar
                v_vouchers,
                v_clients,
                v_loading_login:computed(()=> store.state.v_loading_login),//loading
                get_search_id,//loading
                user,
            }
        }

    }


</script>