import axios from "axios";

export const vouchers = {
    namespaced: true,
    state: () => ({
        v_vouchers:[],  
        
    }),

   

    mutations: {
        // setName( state, name ){
        //     state.form.name = name;
        // }
        V_VOUCHERS(state, data){
            state.v_vouchers = data
        }
    },
    actions: {
        // setName( { commit }, data ){
        //     commit( 'setName', data );
        // }
        v_actions_vouchers({ commit,rootState}){
            //console.log(rootState)
            rootState.v_loading_login = {status:true,message:'vouchers'} 
            let config = {
                headers: {
                'Authorization': 'Bearer ' + rootState.v_user.accessToken,
                'Accept':'application/json'
                }}

            return axios.get(rootState.v_url_global+'/vouchers',config)
              .then(response => {
                rootState.v_loading_login = {status:false,message:''} 
                commit('V_VOUCHERS',response.data)
                })
              .catch(e=>console.log(e));
           
          },
    },
    getters:{
       
    }
}