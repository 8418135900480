<template>
     <!-- <h1>
        Products
    </h1> -->
    <!-- <input v-model="query" type="text">
    <p v-html="query"></p> -->
    <!-- <button @click="traer_productos"> Traer o actualizar productos</button> -->
   <transition>
        <LoadingComponent v-if="v_loading_login.status" :message="v_loading_login.message"></LoadingComponent>  
    </transition>

    <div class="container">
        <div class="card">
            <div class="card-header">
                <h1>
                    Categorias
                </h1>
                <!-- <p v-html="query"></p> -->
                <div class="input-group mb-3">
                    <input  v-model="query" type="text" class="form-control" placeholder="Buscar">
                    <span class="input-group-text" id="basic-addon1">
                       <CategoryCreateComponent :category_id="null"/>
                    </span>
                </div>
                
            </div>
            <div class="card-body">
                <table class="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Titulo</th>
                        <th scope="col">id Categoria padre</th>
                        <th scope="col">Codigo</th>
                        <th scope="col">Editar</th>
                        <th scope="col">Borrar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="mb-1" v-for="(category, index) in searchedCategories" :key="index">
                            <td> 
                                {{category.id}}
                            </td>
                            <td>{{category.title}}</td>
                            <td>{{category.parent_id}}</td>    
                            <td>{{category.code}}</td>
                            <td>
                                <CategoryEditComponent :user="user" :category_props="category"/>
                                
                            </td>
                            <td>
                                <CategoryDeleteComponent :user="user" :category_props="category"/>
                                
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
                <ul class="d-none">
                    <li>{{v_parent_second_categories}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { ref,onMounted,computed } from 'vue';
//import {  useRouter  } from 'vue-router'
import { useStore } from "vuex";
import {  useRouter  } from 'vue-router'
import { useToast } from 'vue-toastification';

import LoadingComponent from '../components/general/LoadingComponent.vue';
import CategoryCreateComponent from '../components/categories/CategoryCreateComponent.vue'
import CategoryEditComponent from '../components/categories/CategoryEditComponent.vue'
import CategoryDeleteComponent from '../components/categories/CategoryDeleteComponent.vue'


export default {
    name:'Category',
    components:{
        LoadingComponent,
        CategoryCreateComponent,
        CategoryEditComponent,
        CategoryDeleteComponent,
    },
    setup(){
        const toast = useToast();
        const store = useStore();
        const router = useRouter()

        const user = ref({});
        const query = ref("");

        const v_categories = computed(()=> store.state.categories.v_categories)//get data categories
        const v_error_login = computed(()=> store.state.v_error_login)//get data si hay un error en login


        const searchedCategories = computed(() => {
            let arr_categories = [];
            arr_categories = v_categories.value//categorias en general
            //arr_categories = store.getters['categories/v_parent_categories']//solo  categorias padres
            //arr_categories = store.getters['categories/v_parent_categories'](params)
            //console.log(arr_categories)
            return arr_categories.filter((category) => {
                return (
                category.title
                    .toLowerCase()
                    .indexOf(query.value.toLowerCase()) != -1
                );
            });
        });

        const get_categories = () => {
            store.dispatch('categories/v_actions_categories' )
        };
        onMounted(() => {
            store.dispatch('v_action_login').then(//esperar que verfique y actualizar en localstorage y vuex para luego:
                () => {
                    if (v_error_login.value){//si es verdadero el error de login entonces:
                        toast.error('Ingrese sus datos')
                        router.push({name:'login'})//Redirigir a login 
                    }
                    else {
                        user.value = store.state.v_user;//usuario actual
                        get_categories()
                    }
                }
            )
        });

        return{
            get_categories,
            query,
            user,
            searchedCategories,
            v_categories,
            v_loading_login:computed(()=> store.state.v_loading_login),//loading
            v_parent_second_categories:computed(()=>store.getters['categories/v_parent_second_categories'])

        }
    }
}
</script>