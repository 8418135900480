<template>
    <button :disabled="disabled" @click="toggle_state_modal"> Cerrar turno</button>
    <div :class="!state_modal?'d-none':'d-block bg-modal'" class="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Cerrar turno : {{user.user.name}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="toggle_state_modal"></button>
                </div>
                <div class="modal-body">
                    <p>Fondo abierto: {{turn.fund_cash}}</p>
                    <p>Fecha de apertura: {{turn.date_open}}</p>
                    <p>Fecha de cierre: {{turn.cash_close}}</p>
                    <form>
                        <div class="form-floating mb-3">
                            <input v-model="turn.cash_close" type="number" class="form-control" placeholder="Monto" autocomplete="on">
                            <label for="floatingInput">Fondos de cierre</label>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" @click="toggle_state_modal" class="btn btn-secondary">Cerrar</button>
                    <button type="button" @click="close_turn" class="btn btn-primary">Cerrar Turno</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {ref,onMounted} from 'vue'
import axios from 'axios';
import { useStore } from 'vuex';

export default {
    name:'turnClose',
    props:['disabled','user','turn_select'],
    setup(props){
        const store = useStore()
        const state_modal = ref(false);
         const turn = ref({
             fund_cash:null,
             date_open:null,
             date_close:null,
             cash_close:null,
             user_id:null,
             status:null,
         })

        function toggle_state_modal(){
            state_modal.value = !state_modal.value
        }

        function close_turn(){
            console.log('dd');
            console.log(props.user)
            let config = {
                headers: {
                'Authorization': 'Bearer ' + props.user.accessToken,
                'Accept':'application/json'
                }
            }
            axios.patch(store.state.v_url_global+'/turns/'+props.turn_select.id,{
                cash_close:turn.value.cash_close,
                date_close:new window.Date(),
                status:'close'
                
                
            },config).then((response) => {
                console.log(response.data)
                store.dispatch('turns/v_actions_turns' )
                toggle_state_modal()
                //client.value = response.data
                
            }).catch((error)=>{
                console.log(error)
            })
        }

        onMounted(()=>{
            turn.value = props.turn_select 
        })
        
        return{
            close_turn,
            toggle_state_modal,
            state_modal,
            turn,

        }
    }
}


</script>