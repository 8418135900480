<template>
    <button @click="toggle_state_modal" class="btn btn-outline-success"><img src="../../assets/square-pen.svg" width="15" alt=""></button>
    <div :class="!state_modal?'d-none':'d-block bg-modal'" class="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Editar: {{product.name}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="toggle_state_modal"></button>
                </div>
                <div class="modal-body">
                     <!-- <pre>
                        {{product_props}}
                    </pre>  -->
                    <form>
                        <div class="mb-3">
                            <label for="category" class="col-form-label">Categoria:</label>
                            <v-select v-model="product.category_id" :options="v_categories" :reduce="(option) => option.id " label="title">
                            </v-select> 
                        </div>

                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Nombre:</label>
                            <input v-model="product.name" type="text" class="form-control" >
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Descripcion:</label>
                            <textarea v-model="product.description" type="text" class="form-control" ></textarea>
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Precio fijo:</label>
                            <input v-model="product.amount" type="number" class="form-control" >
                        </div>
                     

                        <div class="mb-3">
                        <label for="recipient-name" class="col-form-label">Precio sugerido:</label>
                        <input required v-model="product.amount_suggested" type="number" class="form-control">
                    </div>
                    </form>
                    <hr>
                        <ProductCreateComponent 
                        :btn_name="'Crear un subproducto'" 
                        :parent="true"
                        :product_id="product.id"/>
                    <div>
                        <SubproductComponent :subproducts=product.children></SubproductComponent>
                    </div>
                    
                    
                </div>
                <div class="modal-footer">
                    <button type="button" @click="toggle_state_modal" class="btn btn-secondary">Cerrar</button>
                    <button type="button" @click="edit_product" class="btn btn-primary">Guardar Cambios</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { useToast } from "vue-toastification";
import { ref,onMounted,onUpdated, computed } from 'vue';
import { useStore } from "vuex";
import axios from 'axios';
import ProductCreateComponent from "./ProductCreateComponent.vue";
import SubproductComponent from "./subproducts/SubproductComponent.vue";

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';


    export default {
        
        name:'ProductEditComponent',
        components:{
            ProductCreateComponent,
            SubproductComponent,
            vSelect
        },

        props: ['user','product_props'],
        setup(props){
            const store = useStore()
            const state_modal = ref(false);
            const toast = useToast();//usar toast
            const product = ref({
                id:null,
                name:'',
                description:'',
                amount:'',
                amount_suggested:'',
                
                subproducts:[],
            });

            const toggle_state_modal = () => {
                state_modal.value = !state_modal.value;
            }

            function update_products(){
               store.dispatch('products/v_actions_products')
            }
            
            const edit_product = () => {
                console.log(props.user)
                let config = {
                    headers: {
                    'Authorization': 'Bearer ' + props.user.accessToken,
                    'Accept':'application/json'
                    }
                }
                axios.patch(store.state.v_url_global+'/products/'+product.value.id,product.value,config).then((response) => {
                    console.log(response.data)
                    //product.value = response.data
                    store.dispatch('products/v_actions_products')
                    toast.success('Producto  editado')
                    
                }).catch((error)=>{
                    console.log(error)
                    toast.error(error)
                })
            }

          
            onUpdated(()=>product.value = props.product_props)
            onMounted(()=>{
                product.value = props.product_props
                
            })

            return {
                
                product,
                state_modal,
                toggle_state_modal,
                edit_product,
                update_products,
                v_categories:computed(()=>store.state.categories.v_categories)
            }
        }
    }
</script>
<style>
.bg-modal{
    background: #00000066;
}

</style>