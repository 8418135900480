<template>

    <button @click="toggle_state_modal" class="btn btn-success btn-sm">
        Crear
    </button>
     <div :class="!state_modal ? 'd-none' : 'd-block bg-modal'" class="modal">
         <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title">Comprobante </h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    @click="toggle_state_modal"
                ></button>
                </div>
                <div v-if="v_user.user && v_products" class="modal-body">

                    usuario :{{v_user.user.name}}
                    <br>
                    Cliente :{{select_client.name}}
                <div class="my-3">
                    <div class="form-floating mb-3">
                        <input v-model="case_lawyer" type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">Caso:</label>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="">

                        <button @click="select_products=[]" class="btn btn-success">
                            Limpiar
                        </button>
                        <div class="form-check form-switch">
                            <input v-model="btn_add" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                            <label class="form-check-label" for="flexSwitchCheckDefault">Activar agregar producto {{btn_add }}</label>
                        </div>

                        <label class="form-label">Seleccione productos:</label>

                        <!-- <pre>{{checkbox_product}}</pre>

                        <ul>
                            <li v-for="prod in v_parent_products" :key="prod.id">
                                <input type="checkbox" :id="prod.id" :value="prod.id" v-model="checkbox_product">
                                <label for="jack">{{prod.name}}</label>
                                <ol>
                                    <li v-for="sub_prod in prod.children" :key="sub_prod.id">

                                        <label :for="sub_prod.id">{{sub_prod.name}}</label>
                                    </li>
                                </ol>
                            </li>
                        </ul> -->
                        <!-- <pre>
                            {{v_products}}
                        </pre> -->
                        <div class="row">
                            <div class="col-12 my-3">
                                <b >Categoria 1er nivel:</b>
                                <v-select v-model="select_cat_first" :options="v_parent_categories" :reduce="(option) => option" label="title">
                                </v-select>

                            </div>
                            <div class="col-12 my-3">
                                <b >Categoria 2do nivel:</b>

                                <v-select v-model="select_cat_second" :options="array_cat_second" :reduce="(option) => option" label="title">
                                </v-select>


                            </div>
                            <div class="col-12 my-3">
                                <b >Categoria 3er nivel:</b>
                                <v-select v-model="select_cat_third" :options="array_cat_third" :reduce="(option) => option" label="title">
                                </v-select>

                            </div>
                        </div>

                        <div v-if="select_cat_third">
                            <!-- <h1>hola</h1> -->
                            <!-- <pre>
                                {{select_products}}
                            </pre> -->
                            <ul>
                                <li v-for="prod in select_cat_third.products" :key="prod.id">
                                    <input type="checkbox" :id="prod.id" :value="prod" v-model="select_products">
                                    <label for="jack">{{prod.name}}</label>
                                    <ul>
                                        <li v-for="sub_prod in prod.children" :key="sub_prod.id">
                                            <input type="checkbox" :id="sub_prod.id" :value="sub_prod" v-model="select_products">
                                            <label :for="sub_prod.id">{{sub_prod.name}}</label>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <!-- <v-select :options="['Canada', 'United States']"></v-select> -->

                        <!-- <v-select v-model="select_products" :options="v_products" :reduce="(option) => option " label="name" taggable multiple>
                        </v-select> -->
                    </div>
                </div>


               <table class="table">
                    <thead>
                        <tr>
                        <th style="width:10px" scope="col">#id</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Subproductos</th>
                        <th scope="col">Producto padre</th>
                        <th scope="col">Precio fijo</th>
                        <th scope="col">Precio Editable</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(product,index) in select_products" :key="index">
                            <th style="width:10px" scope="row">{{product.id}}</th>
                            <td>{{product.name}}</td>
                            <td>
                                {{product.name}}
                            </td>
                            <td>
                                <span v-if="product.parent">{{product.parent.name}}</span>
                            </td>
                            <td>
                                {{product.amount}}
                            </td>
                            <td>
                                <input v-model="select_products[index].amount_suggested" type="text">
                                s/{{product.amount_suggested}}.00
                            </td>
                        </tr>

                    <h1>total: s/{{total}}.00</h1>

                    </tbody>
                </table>


                </div>

                <div class="modal-footer">
                <button
                    type="button"
                    @click="toggle_state_modal"
                    class="btn btn-secondary"
                >
                    Cerrar
                </button>
                <button @click="create_voucher" type="button" class="btn btn-primary">Crear</button>
                </div>
            </div>
        </div>
     </div>

</template>
<script>

import { computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import axios from 'axios';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';


export default {
    name:'voucher-create-component',
    props:['select_client'],
    components:{
        vSelect
    },
    setup(props){
        const store = useStore()
        const toast = useToast()
        const v_user = computed(()=>store.state.v_user)
        const v_products = computed(()=>store.state.products.v_products)
        const v_categories = computed(()=>store.state.categories.v_categories)
        const v_parent_products = computed(()=>store.getters['products/v_parent_products'])
        const case_lawyer = ref('')

        const checkbox_product = ref([])

        //
        const select_cat_first = ref({})//object -> children
        const select_cat_second = ref({})
        const array_cat_second = ref([])

        const select_cat_third = ref(null)
        const array_cat_third = ref({})



        const select_products = ref([])
        const total = ref(0)

        const btn_add = ref(false)


        const plus_prices = () =>{//funcion antigua para agregar cosas

            if (btn_add.value){

                let total_produts = []
                //console.log("This Bio starts with A"+select_products.value.length);
                console.log('antes de '+select_products.value.length);
                select_products.value.map((product)=>{
                total_produts.push(product)
                console.log("This Bio starts with A"+product.children+product.name);

                if(product.children){
                    product.children.map((subproduct)=>{
                        console.log(subproduct)
                        total_produts.push(subproduct)
                    })
                }
            })

            select_products.value = []
            select_products.value = total_produts
            }

            total.value = 0
            select_products.value.map(product => {
                total.value = total.value + parseInt(product.amount_suggested)
            })

        }

        const view_subarrays = () =>{
            if(select_cat_first.value){
                console.log(select_cat_first.value.children)
                array_cat_second.value = select_cat_first.value.children
            }
            else{
                array_cat_second.value = []
                select_cat_second.value = {}
                array_cat_third.value = []
                select_cat_third.value = null
            }

            if(select_cat_second.value){
                console.log(select_cat_second.value.children)
                array_cat_third.value = select_cat_second.value.children
            }else{
                array_cat_third.value = []
                select_cat_third.value = null
            }



        }

        watchEffect(() => view_subarrays());




        const state_modal = ref(false)

        const toggle_state_modal = () => {
            state_modal.value = !state_modal.value
        }

         const create_voucher = () => {
                console.log(v_user.value)
                let config = {
                    headers: {
                    'Authorization': 'Bearer ' + v_user.value.accessToken,
                    'Accept':'application/json'
                    }
                }

                const body = {
                    select_products:JSON.stringify(select_products.value),
                    user:v_user.value.user,
                    client:props.select_client,
                    case:case_lawyer.value,
                }
                    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

                axios.post(store.state.v_url_global+'/vouchers/create',body,config).then((response) => {
                    console.log(response.data)
                    //product.value = response.data
                    toast.success('Creado con exito')
                    store.dispatch('vouchers/v_actions_vouchers').then(()=>toggle_state_modal())


                }).catch((error)=>{
                    console.log(error)
                    toast.error('Ingrese los datos correctamente')
                })
            }

        return{

            select_cat_first,
            select_cat_second,
            array_cat_second,
            select_cat_third,
            array_cat_third,

            v_categories,
            v_parent_products,
            checkbox_product,
            btn_add,
            case_lawyer,
            plus_prices,
            total,
            create_voucher,
            select_products,
            v_products,
            v_parent_categories:computed(()=>store.getters['categories/v_parent_categories']),
            v_user,
            toggle_state_modal,
            state_modal,

        }
    }
}

</script>