<template>
    <h1>
        Pagina destinada a analitica
    </h1>
    
    
    
   
</template>
<script>
import { ref,onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';


export default {
  name: 'HelloWorld',
  setup(){
    const store = useStore()
    const query = ref("");
    const products = ref([]);
    const user = ref({});
    
    const traer_productos = () => {
     let config = {
            headers: {
            'Authorization': 'Bearer ' + user.value.accessToken,
            'Accept':'application/json'
            }
        }
        axios.get(store.state.v_url_global+'/products',config).then((response) => {
        console.log(response.data)
        products.value = response.data
        })
    };

    onMounted(() => {
        if (localStorage.getItem('UserLogin')){
            user.value = JSON.parse(localStorage.getItem('UserLogin'));
        }
    })

    return {
        user,
        query,
        products,
        traer_productos
        };
  }
}
</script>
