<template>
     <!-- <pre>
        {{v_user}}
        {{v_login}}
    </pre> -->
    <!--
    <button @click="c_send_data_login">v_test</button>
    <button @click="get_user">v_user</button> -->
  <success-login
  v-if="v_user.user"
  :user="v_user"
  ></success-login>
  <div v-else class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="card-body p-4 p-sm-5">
            
            <h5 class="card-title text-center mb-5 fw-light fs-5">
              Inicio de sesion 
              <hr>
              <span>josuejftz@gmail.com</span><br>
              <span>123456</span>
            </h5>
            <form>
              <div class="form-floating mb-3">
                <input v-model="v_login.email" type="email" class="form-control" placeholder="name@example.com" autocomplete="on">
                <label for="floatingInput">Email</label>
              </div>
              <div class="form-floating mb-3">
                <input v-model="v_login.password" type="password" class="form-control" placeholder="Password" autocomplete="on">
                <label for="floatingPassword">Contraseña</label>
              </div>

              <div class="mb-3">
                <!-- <a class="" for="rememberPasswordCheck">
                  Recuperar mi contraseña
                </a> -->
              </div>
              <div class="d-grid">
                <button @click.prevent="get_user" class="btn btn-primary btn-login text-uppercase fw-bold">Entrar</button>
              </div>
              <hr class="my-4">
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { useToast } from "vue-toastification";
import { computed,onMounted  } from 'vue';
//import { computed,onMounted,watchEffect  } from 'vue';
//import axios from 'axios'
import {useStore} from "vuex";
import successLogin from './../components/login/successLogin.vue'


export default {
  components:{
    'success-login':successLogin,
  },
    setup() {

      const toast = useToast();

      const store = useStore();    

      const v_user = computed(()=> store.state.v_user)
           

      function get_user(){
        store.dispatch('v_action_login')
        .then(data => {//Despues  de enviar datos a vuex evalua si  hay un  error
          console.log(data)
          if (store.state.v_status_api == 'error'){
            console.log('no hay')
            toast.error('error')
          }
          else if(store.state.v_status_api == 'success'){
            toast.success('Bienvenido :'+v_user.value.user.name)
          }
        })
        
        
      }  
        
        onMounted(() => {
            if (localStorage.getItem('UserLogin')){
                get_user();
            } 
        })

        return{
            toast,
            v_login: computed(() => store.state.v_login),
            v_user,
            
            get_user,
            
            
            
        }
    },
}
</script>