<template>
    <button @click="toggle_state_modal" class="btn btn-success"> Editar un cliente</button>
    <div :class="!state_modal?'d-none':'d-block bg-modal'" class="modal">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Editar un cliente</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="toggle_state_modal"></button>
            </div>
            <div class="modal-body">
                <!-- <pre>
                    {{client_props}}
                </pre> -->
                <form>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Nombre:</label>
                            <input v-model="client.name" type="text" class="form-control" >
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Apellidos:</label>
                            <input v-model="client.last_name" type="text" class="form-control" >
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Tipo de documento:</label>
                            <v-select v-model="client.type_doc" :options="['LIBRETA ELECTORAL O DNI','CARNET DE EXTRANJERIA','PASAPORTE','OTROS']"></v-select>
                        </div>
                        
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Numero de documento:</label>
                            <input v-model="client.number_doc" type="text" class="form-control" >
                        </div>
                      
                        <div class="mb-3">
                            <label for="">Tipo de cliente</label>
                              <v-select v-model="client.type_client" :label="data_type_client.label" :reduce="country => country.value" :options="data_type_client"></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Dirección:</label>
                            <input v-model="client.address" type="text" class="form-control" >
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">RUC:</label>
                            <input v-model="client.RUC" type="text" class="form-control" >
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Email:</label>
                            <input v-model="client.email" type="text" class="form-control" >
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Numero telefono/celular:</label>
                            <input v-model="client.number_phone" type="text" class="form-control" >
                        </div>
                        
                    </form>

            </div>
            <div class="modal-footer">
                <button type="button" @click="toggle_state_modal" class="btn btn-secondary">Cerrar</button>
                <button type="button" @click="create_client" class="btn btn-primary">Editar cliente</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>


import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { ref,onMounted } from 'vue';
import axios from 'axios';
import vSelect from 'vue-select'


    export default {
        name:'ClientEditComponent',
        components:{
            'v-select':vSelect
        },
        props: ['user','client_props'],
        setup(props){
            const store = useStore()
            const state_modal = ref(false);
            const toast = useToast();
            const data_type_client = ref([
                {value:'normal',label:'Normal'},
                {value:'regulares',label:'Regulares'},
                {value:'VIP',label:'VIP'},
            ])
            const client = ref({
                name:'',
                last_name:'',
                type_doc:'',
                number_doc:'',
                type_client:'',
                address:'',
                RUC:'',
                email:'',
                number_phone:'',
            });

            const toggle_state_modal = () => {
                state_modal.value = !state_modal.value;
            }

            function clear_inputs(){
               
            }

            const create_client = () => {
                console.log(props.user)
                let config = {
                    headers: {
                    'Authorization': 'Bearer ' + props.user.accessToken,
                    'Accept':'application/json'
                    }
                }
                axios.patch(store.state.v_url_global+'/clients/'+client.value.id,client.value,config).then((response) => {
                    console.log(response.data)
                    //client.value = response.data
                    clear_inputs();
                    store.dispatch('clients/v_actions_clients')
                    toast.success('Editado correctamente')
                }).catch((error)=>{
                    console.log(error)
                    toast.error(error)
                })
            }
            onMounted(()=>{
                client.value = props.client_props
            })

            return {
                data_type_client,
                client,
                state_modal,
                toggle_state_modal,
                create_client,
            }
        }
    }
</script>
<style>
.bg-modal{
    background: #00000066;
}

</style>