<template>
    <button @click="toggle_delete" class="btn btn-sm btn-outline-danger">
        <img src="../../../assets/trash-can.svg" width="15" alt="">
    </button>
    <div :class="!modal_delete?'d-none':'d-block bg-modal'" class="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Borrar producto</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="toggle_delete"></button>
                </div>
                <div class="modal-body">
                    <button @click="delete_product" type="button" class="btn btn-danger" data-bs-dismiss="modal">Borrar permanente:{{product_props.name}}</button>
                </div>
            </div>
        </div>
    </div>
   
</template>
<script>
    
import { useToast } from "vue-toastification";
import {ref} from 'vue'
import axios from 'axios'
import { useStore } from "vuex";


export default {
      
props: ['user','product_props'],
components:{
    
},
setup(props){
    const store = useStore()
    const modal_delete = ref(false)
    const toast = useToast();//usar toast
    function toggle_delete(){
        modal_delete.value = !modal_delete.value
    }


    const delete_product = () => {
                console.log(props.user)
                let config = {
                    headers: {
                    'Authorization': 'Bearer ' + props.user.accessToken,
                    'Accept':'application/json'
                    }
                }
                console.log(props.product_props.id)
                axios.delete(store.state.v_url_global+'/products/'+props.product_props.id,config).then((response) => {
                    console.log(response.data)
                    //product.value = response.data
                    store.dispatch('products/v_actions_products')
                    toast.warning('Producto  Borrado')
                    
                }).catch((error)=>{
                    console.log(error)
                    toast.error(error)
                })

                toggle_delete()
            }


    return{
        modal_delete,
        toggle_delete,
        delete_product,
    }
}



}

</script>
