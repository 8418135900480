<template>
    <div class="container">
        <div class="row">
        <div class="col-12">
            <turnCreate v-if="v_user.user" :disabled="btns.open_turn" :user="v_user"></turnCreate>
            <!-- <button :disabled="btns.open_turn">Abrir turno {{btns.open_turn}}</button> -->
            <!-- <button :disabled="btns.close_turn">cerrar turno {{btns.close_turn}}</button> -->
            <TurnClose v-if="v_user.user && select_turn_close.id" :disabled="btns.close_turn" :user="v_user" :turn_select="select_turn_close" />
            <button :disabled="btns.close_turn">Retiro / Deposito</button>
            <button>Consulta cuentas</button>
        </div>
    </div>
    </div>
    <div>
        <p v-if="v_user.user">{{v_user.user.name}}</p>
        
            <pre class="d-none">
            clients:{{v_clients}}
            </pre>
            
            <p>{{now}}</p>
          
    </div>
    <h1>caja

    </h1>
</template>

<script>
//import { ref,onMounted,computed } from 'vue';
import moment from 'moment';
//import { format } from 'path';
import { ref,computed,onMounted,watchEffect  } from 'vue';
//import axios from 'axios';  

import {useStore} from "vuex";
import turnCreate from "./../components/turns/turnCreate.vue"
import TurnClose from '../components/turns/turnClose.vue';
import { useToast } from 'vue-toastification';
import {  useRouter  } from 'vue-router'

import 'moment/locale/es'  // without this line it didn't work
require("moment/min/locales.min");
moment.locale('es')

export default {
   name: 'client',
   components:{
        turnCreate,TurnClose
    },
  setup(){
    const store = useStore();
    const toast = useToast()
    const router = useRouter()
    let now = moment()//ahora 
    const v_error_login = computed(()=> store.state.v_error_login)//get data si hay un error en login
    const user = ref({});
    const v_clients =  computed(() => store.state.clients.v_clients)
    const v_user = computed(() => store.state.v_user)
    const select_turn_close = ref({})


    const btns = ref({
        open_turn:false,
        close_turn:false,
    })
 
  
     watchEffect((  ) => {
        [...store.state.turns.v_turns].map(turn => {
        if(turn.status === 'open'){//Hay un turno abierto
            //console.log('computed '+ turn.status)
            btns.value.open_turn = true
            btns.value.close_turn = false
            select_turn_close.value = turn
        }else if(turn.status === 'close'){//no hay turnos abiertos
            btns.value.open_turn = false
            btns.value.close_turn = true
        }

        
        
    })
    })



    onMounted(()=>{//verificar si estas logeado
        
        store.dispatch('v_action_login').then(//esperar que verfique y actualizar en localstorage y vuex para luego:
        () => {
                //console.log('hola'+v_error_login.value)
                if (v_error_login.value){//si es verdadero el error de login entonces:
                    toast.error('Ingrese sus datos')
                    router.push({name:'login'})//Redirigir a login 
                }
                else {
                    user.value = store.state.v_user;//usuario actual
                    store.dispatch('turns/v_actions_turns' )
                }
            }
          
        )
        .catch((e)=>console.log('errorrrrr:'+e))
    })

    function format_date(value){
        if(value.value && moment(value.value,'YYYY-MM-DD', false).isValid()){//si hay fecha
            //console.log(value)
            return moment(value.value).format('DD MMMM YYYY hh:mm:ss');
        }
        return 'Esperando cierre de caja';
    }

    return {
        select_turn_close,
        btns,
        format_date,
        now,
        v_user,
        v_clients,
        v_turns:computed(()=>store.state.turns.v_turns)
        };
  }
}


</script>