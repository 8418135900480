<template>
  <div id="nav">
    <router-link to="/">Inicio</router-link> |
    <router-link to="/about">About</router-link> | 
    <router-link to="/login">Login</router-link> |
    <router-link to="/trello">trello</router-link> | 
    <router-link to="/client">Clientes</router-link> |
    <router-link to="/category"> Categories</router-link> |
    <router-link to="/product"> Productos</router-link> |
    <router-link to="/voucher"> Voucher</router-link> |
    <router-link to="/cash"> Caja</router-link> |
    <a v-if="user.user">User: {{user.user.name }}</a>
  </div>
  <router-view/>
</template>
<script >
import {computed} from 'vue';
import {useStore} from "vuex";
export default {
    setup() {
      const store = useStore(); 

      let user = computed(function () {
          return store.state.v_user
        });


      return{
        user
      }
    }
}



</script>


<style>
</style>  